// https://getbootstrap.com/docs/4.5/getting-started/webpack/
// npm install --save jquery popper.js
// npm install --save @types/jquery
// import $ from 'jquery';
// npm install --save @types/bootstrap
// import 'bootstrap';
// MAIS VALE FAZER O COPY SENÃO ISTO FICA COM 1.98MB

// https://greensock.com/docs/NPMUsage
// import { TweenMax, Quart, Quad, Expo } from 'gsap';
// npm install gsap
// npm install --save @types/gsap

// import {} from 'plyr';
// import Plyr from './plyr';
//// import { PlyrEvent } from "../../../source/includes/js/plyr";
//// declare var Plyr: any;
import Plyr from 'plyr';
import { PlyrEvent } from 'plyr';

// import newsletterValid from './newslettervalidacao';

import imagesLoaded from 'imagesloaded';
// npm install imagesloaded
// npm install --save @types/imagesloaded

import Rellax from 'rellax';
// npm install rellax --save
// npm install --save @types/rellax

import ScrollReveal from 'scrollreveal';
// npm install scrollreveal
// npm install --save @types/scrollreveal

// npm install sharer.js

import smoothscroll from 'smoothscroll-polyfill';
// import {} from 'bootstrap';


import 'lightgallery.js';
// npm install lightgallery.js
declare var lightGallery: any;
// import lightGallery from './lightgallery.js';

// declare var lity: any;


// https://github.com/w3c/IntersectionObserver/tree/main/polyfill
// npm install intersection-observer
import 'intersection-observer';

///////////////////////////////////////////////////////// MODULOS CASEIROS
// const main_menu: HTMLElement = <HTMLElement>document.getElementById('main-menu');
const header_el: HTMLElement = <HTMLElement>document.querySelector('.header');
const pattern: HTMLElement = <HTMLElement>document.querySelector('.pattern');
let playerEventTarget: any; // Object;


import cookieConsent from './cookieconsent/cookieconsent';


// import modalBootstrapProjectosApoios from '../../templates/modules/modals/modalBootstrapProjectosApoios/modalBootstrapProjectosApoios';
// import modalLightGalleryIframeHtml from '../../templates/modules/modals/modalLightGalleryIframeHtml/modalLightGalleryIframeHtml'
// import fbshare from '../../templates/modules/sociais/fbshare/fbshare';

// import modalBootstrapHtml from '../../templates/modules/modals/modalBootstrapHtml/modalBootstrapHtml';

// import carouselEmblaImgs from '../../templates/modules/carousels/carouselEmblaImgs/carouselEmblaImgs';





////////////////////////////////////////////////////////////////// PRIMARY COLOR
const primary_color: String =  <String>getComputedStyle(document.documentElement).getPropertyValue('--primary-color');




//////////////////////////////////////////////////////////////////////// MOBILE
const ua = navigator.userAgent.toLowerCase();
const isAndroid = ua.indexOf('android') > -1;
const isIphone = ua.indexOf('iphone') > -1;
const isIpad = ua.indexOf('ipad') > -1;

////////////////////////////////////////////////////////////////// INTERSECTION OBSERVER
// const intersect_observer: IntersectionObserver = new IntersectionObserver ((entries: object, self: object) => { // 1ª function

//     /* if (entries[0].target.classList.contains('video') === true) {
//         // const videoPlayerEl: HTMLElement = <HTMLElement>document.querySelector('.video');
//         if (entries[0].isIntersecting === true) {
//             // console.log('observer entries', videoPlayerEl); // entries.isIntersecting
//         }
//     } */

//     console.log('observer', entries);
//     /* if (entries[0].target.classList.contains('lazyloada')) {
//         if (entries[0].isIntersecting === true) {
//             // console.log('imagem carregada', entries[0].target.dataset.src);
//             // entries[0].target.setAttribute('src', entries[0].target.dataset.src);
//             // entries[0].target.classList.add('img-loaded');
//         }
//     } */

// }, { // 2ª function
//     root: null,
//     rootMargin: '0%',
//     threshold: 0
// });

let options = {
    root: null,
    rootMargin: '0px',
    threshold: 0
};

function intObserverCallback (entries: any, self: object) {

    entries.forEach(function (entry: any) {

        if (entry.target.classList.contains('video') === true) {
            // if (entry.isIntersecting === true) {
            if (entry.intersectionRatio === 0) {
                // console.log('VIDEO ON SCREEN', entry.intersectionRatio);
                playerEventTarget.pause();
            }
        }

        // if (entry.target.classList.contains('carousel')) {
        if (entry.target.classList.contains('header')) {
            if (entry.intersectionRatio > 0) {
                // console.log('=== ON SCREEN');
                jQuery('.header__carousel').carousel('cycle');
                window.onfocus = function () {
                    // console.log('=== FOCUS PLAY');
                    jQuery('.header__carousel').carousel('cycle');
                    pattern.classList.remove('paused');
                }
                window.onblur = function () {
                    // console.log('=== BLUR PAUSE');
                    jQuery('.header__carousel').carousel('pause');
                    pattern.classList.add('paused');
                }
                pattern.classList.remove('paused');
            } else {
                // console.log('=== OFF SCREEN');
                jQuery('.header__carousel').carousel('pause');
                pattern.classList.add('paused');
            }
        }

        if (entry.target.classList.contains('lazyloada-imgwrapper')) {

            const loader_el: HTMLElement = <HTMLElement>entry.target.parentNode.querySelector('.loader');
            const loader_exists: boolean = document.body.contains(loader_el);

            if (entry.intersectionRatio > 0) {
                const imagem_el: HTMLElement = <HTMLElement>entry.target.querySelector('.lazyloada');
                imagem_el.setAttribute('src', String(imagem_el.dataset.src));

                const imagem_el_exists: boolean = document.body.contains(imagem_el);
                if (imagem_el_exists === true) {

                    const lazyImgLoad = imagesLoaded(entry.target);

                    lazyImgLoad.on( 'done', function( instance: any ) {
                        entry.target.classList.add('img-loaded');
                        if (loader_exists === true) {
                            entry.target.parentNode.removeChild(entry.target.parentNode.querySelector('.loader'));
                        }
                    });

                    lazyImgLoad.on( 'fail', function( instance: any ) {
                        console.log('FAIL - all images loaded, at least one is broken', instance.elements[0]);
                    });

                    intersect_observer.unobserve(entry.target);
                }

            }
        }

    });

}

const intersect_observer: IntersectionObserver = new IntersectionObserver(intObserverCallback, options);


/* let observer: IntersectionObserver;
function createObserver() {
    let options = {
      root: null,
      rootMargin: "0px",
      threshold: 0
    };
    observer = new IntersectionObserver(handleIntersect, options);
} */

/* function addObserver(entry: HTMLElement) {
    observer.observe(entry);
} */

// function handleIntersect (entries: object, observer: IntersectionObserver) {

    // entries.forEach(function (entry: HTMLElement) {

        /* if (entry.target.classList.contains('header__carousel') === true) {
            if (entry.isIntersecting === false) {
                // console.log('OFF SCREEN');
                $('.carousel').carousel('pause');
                window.onblur = function () {
                    // console.log('BLUR PAUSE');
                    $('.carousel').carousel('pause');
                }
            } else {
                // console.log('ON SCREEN');
                window.onfocus = function () {
                    // console.log('FOCUS PLAY');
                    $('.carousel').carousel('cycle');
                }
                window.onblur = function () {
                    // console.log('BLUR PAUSE');
                    $('.carousel').carousel('pause');
                }
            }
        }

        if (entry.target.classList.contains('video') === true && entry.isIntersecting === false) {
            playerEventTarget.pause();
        } */
        /* if (entries[0].isIntersecting === true) {
            console.log('handleIntersect img', entries[0].target);
        } */
        /* if (entry.target.classList.contains('lazyloada')) {
            if (entry.isIntersecting === true) {
                // entry.target.children[0].setAttribute('src', entry.target.children[0].dataset.src);
                entry.target.setAttribute('src', entry.target.dataset.src);
                entry.target.classList.add('img-loaded');
            }
        } */

    // });
// }


//////////////////////////////////////////////////////////////////////// HEIGHT 100VH MOBILE
// https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
function mobileScreenHeight() {
    if (window.matchMedia('(min-width: 768px)').matches && !isAndroid && !isIphone && !isIpad) { // DESKTOP
        // console.log('DESKTOP');
    } else { // MOBILE
        // console.log('MOBILE');
        // const header_section:HTMLElement = <HTMLElement>document.getElementsByClassName('header')[0];
        const header_el_exists: boolean = document.body.contains(header_el);
        if (header_el_exists === true) {
            header_el.style.height = Number(window.innerHeight) + 'px';
        }
        document.body.classList.add('mobile');
    }
}
window.addEventListener("orientationchange", function(event: Event){
    const afterOrientationChange = function() {
        mobileScreenHeight();
        window.removeEventListener('resize', afterOrientationChange);
    };
    window.addEventListener('resize', afterOrientationChange);
});



////////////////////////////////////////////////////////////////// REDES SOCIAIS FOOTER ROLLOVER
if (window.matchMedia('(min-width: 768px)').matches && !isAndroid && !isIphone && !isIpad) { // DESKTOP
    const sociaisFooter: NodeList = <NodeList>document.querySelectorAll('.footer__social-link');
    const sociaisFooterTotal: number = Number(sociaisFooter.length);
    for (let $scf = 0; $scf < sociaisFooterTotal; ++$scf) {

        sociaisFooter[$scf].addEventListener('mouseenter', function (event: Event) { // MouseEvent
            event.preventDefault();
            const thisCurrentTarget: HTMLElement = <HTMLElement>event.currentTarget;
            thisCurrentTarget.style.borderColor = String(thisCurrentTarget.dataset.bgcol);
        });

        sociaisFooter[$scf].addEventListener('mouseleave', function (event: Event) { // MouseEvent
            event.preventDefault();
            const thisCurrentTarget: HTMLElement = <HTMLElement>event.currentTarget;
            thisCurrentTarget.style.borderColor = '#fff';
        });
    }
}

////////////////////////////////////////////////////////////////// SCROLL ANIMADO
function scrollThere (duration: number, target: HTMLElement, scrollOffset: number = 0) {
    //const targetEl: HTMLElement = <HTMLElement>document.getElementById(target.substring(1));
    window.scrollTo({
        top: Number(target.offsetTop - scrollOffset),
        behavior: 'smooth'
    });
}

////////////////////////////////////////////////////////////////// SCROLL POLYFILL
smoothscroll.polyfill();


////////////////////////////////////////////////////////////////// HEADER SETA SCROLL
const seta_scroll: HTMLButtonElement = <HTMLButtonElement>document.querySelector('.seta-scroll');
const seta_scroll_exists: boolean = document.body.contains(seta_scroll);

if (seta_scroll_exists === true) {
    seta_scroll.addEventListener('click', function (event:MouseEvent) {
        const primeiro: HTMLElement = <HTMLElement>document.getElementById('primeiro');
        scrollThere(1000, primeiro);
    });
}

////////////////////////////////////////////////////////////////// LAZYLOAD CASEIRO
function correLazyLoad () {
    // const lazyloada_wrapper: HTMLElement = <HTMLElement>document.getElementsByClassName('lazyloada-wrapper')[0];
    const lazyloada: NodeList = <NodeList>document.querySelectorAll('.lazyloada-imgwrapper');
    const lazyloada_exists: boolean = document.body.contains(lazyloada[0]);
    // const galeria_images: NodeList = <NodeList>document.querySelectorAll('.galeria__image');

    if (lazyloada_exists === true) {
        lazyloada.forEach(function (lazy_item: any) { // HTMLElement
            // addObserver(item);
            if (lazyloada.length > 0) {
                intersect_observer.observe(lazy_item);
            }
            // console.log('correLazyLoad', lazy_item, lazyloada.length);
        });
        // intersect_observer.observe(galeria_images);
    }
}

////////////////////////////////////////////////////////////////// EQUAL HEIGHTS
function equalHeights (selector: string) {
    let elements: any = document.querySelectorAll(selector);
    let totalElements: number = Number(elements.length);
    let tallest: number = 0;
    let $h: number, element: any, elementHeight: number;

    for ($h = 0; $h < totalElements; ++$h) {
        element = elements[$h];
        elementHeight = element.offsetHeight;
        tallest = (elementHeight > tallest) ? elementHeight : tallest;
    }

    for ($h = 0; $h < totalElements; ++$h) {
        elements[$h].style.height = tallest + 'px';
    }
}

if (window.matchMedia('(min-width: 768px)').matches) { // DESKTOP
    equalHeights('.equal-height');
    equalHeights('.alerta-equal-height');
}


/////////////////////////////////////////////////////////////////// PLYR VIDEO
function correVideo () {
    // https://github.com/sampotts/plyr/issues/1222#issuecomment-431459475   <--// watch later / share / related videos
    const plyr_player_wrapper_el: HTMLElement = <HTMLElement>document.querySelector('.video');
    // const plyrPlayerEl: HTMLElement = <HTMLElement>document.getElementsByClassName('video-plyr')[0];
    const plyr_player_wrapper_el_exists: Boolean = document.body.contains(plyr_player_wrapper_el);

    if (plyr_player_wrapper_el_exists === true) {

        // https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
        // OS BROWSERS DEIXARAM DE PERMITIR AUTOPLAYs
        const plyr_player = Plyr.setup('.video-plyr', {
            //controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'pip', 'airplay', 'fullscreen'],
            controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen']
            // portrait: true,
            // quality: { default: 'hd1080'} // { default: 'default', options: ['hd2160', 'hd1440', 'hd1080', 'hd720', 'large', 'medium', 'small', 'tiny', 'default'] }
            //controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'pip', 'airplay', 'fullscreen']
        });

        // https://stackoverflow.com/a/48025115
        plyr_player_wrapper_el.addEventListener('ready', function (event: Event) { // PlyrEvent
            playerEventTarget = (<PlyrEvent>event).detail.plyr;
            const playerEventTargetEl: HTMLElement = <HTMLElement>(<PlyrEvent>event).currentTarget;
            // console.log('playerEventTargetEl', playerEventTargetEl);
            window.onblur = function () {
                (<PlyrEvent>event).detail.plyr.pause();
            }
            // addObserver(playerEventTargetEl);
            intersect_observer.observe(playerEventTargetEl);
        });

    }
}

////////////////////////////////////////////////////////////////// MODAL LIGHTGALLERY
function modalLightGallery() {
    const galeria_el: HTMLElement = <HTMLElement>document.getElementById('galeria');
    const galeria_el_exists: boolean = document.body.contains(galeria_el);

    if (galeria_el_exists === true) {
        lightGallery(galeria_el, {
            selector: '.galeria__link',
            mode: 'lg-fade',
            download: false,
            // counter: false,
            slideEndAnimatoin: false,
            getCaptionFromTitleOrAlt: false,
            loop: false,
            enableDrag: false,
            thumbnail: false,
            pager: false,
            // exThumbImage: 'data-thumb',
            // cssEasing: 'cubic-bezier(0.680, -0.550, 0.265, 1.550)'
        });
    }
}




//////////////////////////////////////////////////////////////////////// RELLAX PARALLAX
// new Rellax();
// const rellax =
new Rellax('.rellax', {
    breakpoints: [576, 768, 1201]
});


//////////////////////////////////////////////////////////////////////// SCROLLREVEAL
function scrollRevela () {
    const intro_p: NodeListOf<Element> = <NodeListOf<Element>>document.querySelectorAll('#primeiro .col-12');
    const destaques_home: NodeListOf<Element> = <NodeListOf<Element>>document.querySelectorAll('.destaques .destaques__wrapper');
    const atalhos_home: NodeListOf<Element> = <NodeListOf<Element>>document.querySelectorAll('.atalhos .col-12');
    const anexos: NodeListOf<Element> = <NodeListOf<Element>>document.querySelectorAll('.anexos .col-12');
    const partilha: NodeListOf<Element> = <NodeListOf<Element>>document.querySelectorAll('.partilha .partilha__wrapper');
    const formulario: NodeListOf<Element> = <NodeListOf<Element>>document.querySelectorAll('.formulario .formulario__wrapper');
    const mapa: NodeListOf<Element> = <NodeListOf<Element>>document.querySelectorAll('.mapa .mapa__image');
    const newsletter: NodeListOf<Element> = <NodeListOf<Element>>document.querySelectorAll('.newsletter .container');
    const apoios: NodeListOf<Element> = <NodeListOf<Element>>document.querySelectorAll('.apoios .row');

    ScrollReveal().reveal(intro_p, { delay: 500, distance: '50px', interval: 150 });

    ScrollReveal().reveal(destaques_home, { delay: 500, interval: 100 });
    ScrollReveal().reveal(atalhos_home, { delay: 500, interval: 100 });

    ScrollReveal().reveal(anexos, { delay: 500, interval: 120 });

    ScrollReveal().reveal(formulario, { delay: 500, interval: 100 });
    ScrollReveal().reveal(mapa, { delay: 500, interval: 100 });

    ScrollReveal().reveal(newsletter, { delay: 500, distance: '50px', interval: 100 });
    ScrollReveal().reveal(apoios, { delay: 500, interval: 120 });

    ScrollReveal().reveal(partilha, { delay: 500, distance: '100px', origin: 'right', interval: 100 });
}



//////////////////////////////////////////////////////////////////////// NEWSLETTER VALIDAÇÃO
// new newsletterValid();




//////////////////////////////////////////////////////////////////////// FACEBOOK API PARTILHA
// new fbshare();





//////////////////////////////////////////////////////////////////////// CAROUSEL
// https://github.com/twbs/bootstrap/blob/v4-dev/dist/js/bootstrap.js
// https://github.com/twbs/bootstrap/blob/v4-dev/js/dist/carousel.js
// https://github.com/twbs/bootstrap/blob/v4-dev/js/src/carousel.js
const carousel_items: NodeList = <NodeList>document.querySelectorAll('.carousel-item');
const total_slides: number = Number(carousel_items.length);
// console.log('total_slides', total_slides);

function correCarousel () {
    // const carousel_el: HTMLElement = <HTMLElement>document.getElementById('header__carousel');

    jQuery('.carousel').carousel({
        // interval: 0,
        pause: false
    });

    // intersect_observer.observe(carousel_el);
    // addObserver(carouselEl);

    /* $('.carousel').on('slide.bs.carousel', function () {
        // console.log('carousel muda');
    }); */
}

if (total_slides > 1) {
    correCarousel();
}


//////////////////////////////////////////////////////////////////////// LINGUAS BOT
const linguas_main_bot: HTMLButtonElement = <HTMLButtonElement>document.querySelector('.linguas-main-bot');
const linguas_lista: HTMLElement = <HTMLElement>document.querySelector('.linguas-lista');
const linguas_main_bot_exist: boolean = document.body.contains(linguas_main_bot);
const area_reservada: HTMLElement = <HTMLElement>document.querySelector('.area-reservada-wrapper a');
const area_reservada_exist: boolean = document.body.contains(area_reservada);

function fechaLinguas () {
    linguas_lista.classList.remove('open');
    if (area_reservada_exist === true) { area_reservada.classList.remove('dimmed'); }
}

if (linguas_main_bot_exist === true) {

    linguas_main_bot.addEventListener('click', function (event: Event) {
        event.preventDefault();
        linguas_lista.classList.toggle('open');
        if (area_reservada_exist === true) { area_reservada.classList.toggle('dimmed'); }
        // console.log('linguas');
    });

    window.onclick = function (event: Event) {
    // header_el.addEventListener('click', function (event: Event) {
        // event.preventDefault();
        // event.stopPropagation();
        const event_target: HTMLElement = <HTMLElement>event.target;
        if (!event_target.classList.contains('linguas-main-bot') && !event_target.classList.contains('linguas-titulo')) {
            fechaLinguas();
            // console.log('Olá!!!!!!!!!!!!!!!!!!!!!', event.target);
        }
    };

    document.addEventListener('keyup', function (key_event: KeyboardEvent) {
        if (key_event.key === 'Escape') { // esc
            fechaLinguas();
        }
    });
}

//////////////////////////////////////////////////////////////////////// IMAGESLOADED
const carousel_el: HTMLElement = <HTMLElement>document.getElementById('header__carousel');
const carousel_el_exists: boolean = document.body.contains(carousel_el);

if (carousel_el_exists === true) {
    const imgLoad = imagesLoaded('.header__carousel-image');

    imgLoad.on( 'done', function( instance: any ) {
        // console.log('HEADER DONE - all images have been successfully loaded', instance.elements.length);
        carousel_el.classList.add('carou-loaded');
    });
}

/*imgLoad.on( 'fail', function( instance: object ) {
    console.log('FAIL - all images loaded, at least one is broken', instance);
}); */

/* imgLoad.on( 'progress', function( instance: object, image: any ) {
    let result: string = image.isLoaded ? 'loaded' : 'broken';
    // console.log( 'PROGRESS image is ' + result + ' for ' + image.img.src );
    console.log( 'PROGRESS image is ' + result + ' for ' + instance );
}); */

// https://javascript.info/onload-ondomcontentloaded
window.addEventListener("load", function(event: Event) { // not only HTML is loaded, but also all the external resources: images, styles etc.
// document.addEventListener('DOMContentLoaded', function() { //the browser fully loaded HTML, and the DOM tree is built, but external resources may be not yet loaded.

    mobileScreenHeight();
    scrollRevela();


    new cookieConsent();
    // new modalBootstrapProjectosApoios();


    // console.log('PAGE LOADED', total_slides);

    intersect_observer.observe(header_el);
    // const carouselEl: HTMLElement = <HTMLElement>document.getElementById('header__carousel');
    // carouselEl.classList.add('carou-loaded');
    //////////////////////////////////////////////////////////////////////// HOME ----------------
    if (document.body.classList.contains('home')) {
        correVideo();
        correLazyLoad();
    }

    //////////////////////////////////////////////////////////////////////// NOTÍCIAS ----------------
    if (document.body.classList.contains('page-template-page-noticias')) {
        correLazyLoad();
    }

    //////////////////////////////////////////////////////////////////////// NOTÍCIAS DETALHE, EMPRESA E QUALIDADE ----------------
    if (document.body.classList.contains('page-template-page-empresa') || document.body.classList.contains('page-template-page-qualidade') || document.body.classList.contains('single-noticias_cpt') || document.body.classList.contains('page-template-page-projectos')) {
        correVideo();
        modalLightGallery();
        correLazyLoad();
    }

    //////////////////////////////////////////////////////////////////////// PRODUTOS ----------------
    if (document.body.classList.contains('page-template-page-produtos')) {
        // new modalLightGalleryIframeHtml();

        // new modalBootstrapHtml();

        correLazyLoad();
    }

    //////////////////////////////////////////////////////////////////////// EXCEPTO HOME / NOTICIAS / CONTACTOS ----------------
    /* if (!document.body.classList.contains('home')  && !document.body.classList.contains('page-template-page-noticias') && !document.body.classList.contains('page-template-page-contactos') ) {
        facebookPartilhaWrapper();
    } */
}, false);