/* export interface IErrorParams {
    duration?: number;
    target?: HTMLElement;
    scrollOffset?: number;
} */

// https://brainsum.github.io/cookieconsent/

export class cookieConsent {

    constructor () {
        this.init();
    }

    public init (): void {
        // https://stackoverflow.com/a/29463433
        // https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/facebook-js-sdk/index.d.ts

        const dados_inline:HTMLElement = <HTMLElement>document.getElementById('dados-inline');
        // console.log('::::::::::::------', dados_inline.dataset.lg);

        $(function() {
            switch(dados_inline.dataset.lg) {
              case 'pt':
                  $(".cc-text").append(' Consulte a nossa <a href="https://sirplaste.pt/politica-de-privacidade/">Política de Privacidade</a>.');
              break;
              case 'en':
                  $(".cc-text").append(' See our <a href="https://sirplaste.pt/en/privacy-policy/"> Privacy Policy</a>.');
              break;
              case 'es':
                  $(".cc-text").append(' Ver nuestro <a href="https://sirplaste.pt/es/politica-de-privacidad/">Política de Privacidad</a>.');
              break;
              case 'fr':
                  $(".cc-text").append(' Voir nos <a href="https://sirplaste.pt/fr/engagement-de-confidentialite/">Engagement de confidentialité</a>.');
              break;
              default:
                  $(".cc-text").append(' Consulte a nossa <a href="https://sirplaste.pt/politica-de-privacidade/">Política de Privacidade</a>.');
            }
        });

        window.CookieConsent.init({
            // More link URL on bar
            modalMainTextMoreLink: '',
            // How lond to wait until bar comes up
            barTimeout: 1000,
            // Look and feel
            theme: {
              barColor: '#3A3A3C',
              barTextColor: '#ffffff',
              barMainButtonColor: '#E35036',
              barMainButtonTextColor: '#ffffff',
              modalMainButtonColor: '#E35036',
              modalMainButtonTextColor: '#ffffff',
            },
            language: {
              // Current language
              current: dados_inline.dataset.lg,
            //   current: 'pt',
              locale: {
                en: {
                    barMainText: 'This website uses cookies to ensure you get the best experience on our website.',
                    barLinkSetting: 'Cookie Settings',
                    barBtnAcceptAll: 'Accept all cookies',
                    modalMainTitle: 'Cookie settings',
                    modalMainText: 'Cookies are small software labels (in the form of a small text file) that are generated on websites and stored in the browsers of the device you are using to navigate a website. ',
                    modalBtnSave: 'Save current settings',
                    modalBtnAcceptAll: 'Accept all cookies and close',
                    modalAffectedSolutions: 'Affected solutions:',
                    learnMore: 'Learn More',
                    on: '',
                    off: '',
                },
                pt: {
                    barMainText: 'Este site usa cookies para garantir que você tem a melhor experiência no nosso site.',
                    barLinkSetting: 'Definições de Cookies',
                    barBtnAcceptAll: 'Aceitar todos os cookies',
                    modalMainTitle: 'Definições de Cookies',
                    modalMainText: 'Os cookies são pequenas etiquetas de software (sob a forma de um pequeno ficheiro de texto) que são geradas nos websites e armazenadas nos navegadores (browsers) do dispositivo que estiver a usar para navegar num website.',
                    modalBtnSave: 'Aceitar as definições actuais',
                    modalBtnAcceptAll: 'Aceitar todos os cookies e fechar',
                    modalAffectedSolutions: '',
                    learnMore: '',
                    on: '',
                    off: '',
                },
                es: {
                    barMainText: 'Este sitio web utiliza cookies para garantizar que obtenga la mejor experiencia en nuestro sitio web.',
                    barLinkSetting: 'Configuración de cookies',
                    barBtnAcceptAll: 'Aceptar todas las cookies',
                    modalMainTitle: 'Configuración de cookies',
                    modalMainText: 'Las cookies son pequeñas etiquetas de software (en forma de un pequeño archivo de texto) que se generan en los sitios web y se almacenan en los navegadores del dispositivo que está utilizando para navegar por un sitio web.',
                    modalBtnSave: 'Guardar la configuración actual',
                    modalBtnAcceptAll: 'Acepta todas las cookies y cierra',
                    modalAffectedSolutions: '',
                    learnMore: 'Saber más',
                    on: '',
                    off: '',
                },
                fr: {
                    barMainText: 'Ce site Web utilise des cookies pour vous garantir la meilleure expérience sur notre site Web.',
                    barLinkSetting: 'Paramètres des cookies',
                    barBtnAcceptAll: 'Acceptez tous les cookies',
                    modalMainTitle: 'Paramètres des cookies',
                    modalMainText: "Les cookies sont de petites étiquettes logicielles (sous la forme d'un petit fichier texte) qui sont générées sur des sites Web et stockées dans les navigateurs de l'appareil que vous utilisez pour naviguer sur un site Web.",
                    modalBtnSave: 'Enregistrer les paramètres actuels',
                    modalBtnAcceptAll: 'Acceptez tous les cookies et fermez',
                    modalAffectedSolutions: '',
                    learnMore: 'Savoir plus',
                    on: '',
                    off: '',
                },
              }
            },

            categories: {
              necessary: {
                needed: true,
                wanted: true,
                checked: true,
                language: {
                  locale: {
                    en: {
                        name: 'Strictly Necessary Cookies',
                        description: '',
                    },
                    pt: {
                        name: 'Cookies estritamente necessários',
                        description: '',
                    },
                    es: {
                        name: 'Cookies estritamente necessários',
                        description: '',
                    },
                    fr: {
                        name: 'Cookies estritamente necessários',
                        description: '',
                    }
                  }
                }
              },
              analytics: {
                needed: false,
                wanted: false,
                checked: false,
                language: {
                  locale: {
                    en: {
                        name: 'Analytics Cookies',
                        description: '',
                    },
                    pt: {
                        name: 'Cookies de desempenho e análise',
                        description: '',
                    },
                    es: {
                        name: 'Cookies de rendimiento y análisis',
                        description: '',
                    },
                    fr: {
                        name: 'Cookies de Análise e pesquisa',
                        description: '',
                    }
                  }
                }
              }
            },
            // List actual services here
            services: {

              // Unique name
              analytics: {
                category: 'analytics',
                type: 'dynamic-script', // Can be: dynamic-script, script-tag, wrapped, localcookie
                search: 'analytics',
                cookies: [
                  {
                    name: '_gid',
                    domain: `.${window.location.hostname}`
                  },
                  {
                    name: /^_ga/,
                    domain: `.${window.location.hostname}`
                  },
                  {
                    name: 'GPS',
                    domain: `.youtube.com`
                  }
                ],
                language: {
                  locale: {
                    en: {
                        name: 'Google Analytics'
                    },
                    pt: {
                        name: 'Google Analytics'
                    },
                    es: {
                        name: 'Google Analytics'
                    },
                    fr: {
                        name: 'Google Analytics'
                    }
                  }
                }
              },

              /* local: {
                category: 'marketing',
                type: 'localcookie', // Can be: dynamic-script, script-tag, wrapped, localcookie
                search: 'nomeCookie',
                cookies: [
                  {
                    name: 'nomeCookie',
                    domain: `.${window.location.hostname}`
                  }        ],
                language: {
                  locale: {
                    en: {
                      name: 'Nome Cookie'
                    },
                    pt: {
                      name: 'PT Nome Cookie'
                    }
                  }
                }
              } */


            }
          });


    } // init
}

export default cookieConsent;